var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "container"
  }, [_c('section', {
    staticClass: "section01",
    class: _vm.loggedClass
  }, [_c('div', {
    staticClass: "keyvisual"
  }, [_c('div', {
    staticClass: "keyvisual__text"
  }, [_c('p', [_vm._v("쇼핑몰 디자인과 기능, 빠르게 pick. Qpick!")]), _c('button', {
    staticClass: "lnk__start ga_f_14",
    on: {
      "click": function click($event) {
        return _vm.movePage('/accounts/signup');
      }
    }
  }, [_vm._v(" 무료 시작하기 ")])]), _vm._m(0)])]), _c('section', {
    staticClass: "section02"
  }, [_vm._m(1), _c('div', {
    staticClass: "feature"
  }, [_c('swiper', {
    ref: "guideSwiper",
    staticClass: "feature__thumb",
    attrs: {
      "options": _vm.guideSwiperOption
    },
    on: {
      "slideChange": _vm.onSlideChange
    }
  }, [_c('swiper-slide', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_visual1.png")
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_visual2.png")
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_visual3.png")
    }
  })])], 1), _c('ul', {
    staticClass: "feature__tab"
  }, [_c('li', {
    class: {
      active: _vm.isTab == 1
    },
    on: {
      "click": function click($event) {
        return _vm.onslideTo(1);
      }
    }
  }, [_vm._m(2), _vm._m(3)]), _c('li', {
    class: {
      active: _vm.isTab == 2
    },
    on: {
      "click": function click($event) {
        return _vm.onslideTo(2);
      }
    }
  }, [_vm._m(4), _vm._m(5)]), _c('li', {
    class: {
      active: _vm.isTab == 3
    },
    on: {
      "click": function click($event) {
        return _vm.onslideTo(3);
      }
    }
  }, [_vm._m(6), _vm._m(7)])])], 1)]), _c('section', {
    staticClass: "section03"
  }, [_c('div', {
    staticClass: "design"
  }, [_c('div', {
    staticClass: "design__control"
  }, [_vm._m(8), _c('button', {
    staticClass: "lnk__more ga_f_15",
    on: {
      "click": function click($event) {
        return _vm.movePage('/introduction/theme-list?id=3');
      }
    }
  }, [_c('span', [_vm._v("전체보기")])])]), _c('div', {
    staticClass: "design__swiper"
  }, [_c('swiper', {
    ref: "themeSwiper",
    attrs: {
      "options": _vm.themeSwiperOption
    }
  }, [_c('swiper-slide', [_c('img', {
    attrs: {
      "src": "https://storage.qpicki.com/qpick/000/000/000/d1c435a40a651a1c51b885d877270e4a.png",
      "alt": ""
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "https://storage.qpicki.com/qpick/000/000/000/15df6399261c697724eb9ba55036b253.png",
      "alt": ""
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "https://storage.qpicki.com/qpick/000/000/002/1bfa0636dea5e7ecb0092c87ed527f15.png",
      "alt": ""
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "https://storage.qpicki.com/qpick/000/000/002/83ff798d897dcf28e691311c857b31fd.png",
      "alt": ""
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "https://storage.qpicki.com/qpick/000/000/002/8e1eea0240cac068883378933292f808.png",
      "alt": ""
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "https://storage.qpicki.com/qpick/000/000/002/3a31bc6b15474f58f3fcc026e6b139f8.png",
      "alt": ""
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "https://storage.qpicki.com/qpick/000/000/003/151c3a3aca25d3f67ef31deafe99d08e.png",
      "alt": ""
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "https://storage.qpicki.com/qpick/000/000/003/349dab3640a14bcad18b31baacb5850c.png",
      "alt": ""
    }
  })])], 1), _vm._m(9)], 1)])]), _vm._m(10), _c('section', {
    staticClass: "section05"
  }, [_c('div', {
    staticClass: "desc05"
  }, [_vm._m(11), _c('button', {
    staticClass: "lnk__more--solid ga_f_16",
    on: {
      "click": function click($event) {
        return _vm.movePage('/introduction/component-list');
      }
    }
  }, [_c('span', [_vm._v("전체보기")])]), _vm._m(12), _c('div', {
    staticClass: "desc05__list desc05__list--mobile"
  }, [_c('swiper', {
    ref: "componentSwiper",
    attrs: {
      "options": _vm.componentSwiperOption
    }
  }, [_c('swiper-slide', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_mframe01.png"),
      "alt": ""
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_mframe02.png"),
      "alt": ""
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_mframe03.png"),
      "alt": ""
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_mframe04.png"),
      "alt": ""
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_mframe05.png"),
      "alt": ""
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_mframe06.png"),
      "alt": ""
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_mframe07.png"),
      "alt": ""
    }
  })])], 1)], 1)])]), _c('section', {
    staticClass: "section06"
  }, [_c('div', {
    staticClass: "desc06"
  }, [_vm._m(13), _vm._m(14), _c('div', {
    staticClass: "desc06__text"
  }, [_c('div', {
    staticClass: "desc06__text--solution"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/bi_mysoho.png"),
      "alt": "mysoho"
    }
  }), _c('p', [_vm._v(" 마이소호는 초기 창업자의 사용에 최적화되어 누구나 쉽게 사용할 수 있고 다양하게 상품을 판매할 수 있는 강력한 기능을 제공합니다. "), _c('button', {
    on: {
      "click": function click($event) {
        return _vm.movePage('interlock-mysoho');
      }
    }
  }, [_vm._v(" 연동방법 보러가기 ")])]), _c('button', {
    staticClass: "lnk__solution ga_f_17",
    on: {
      "click": function click($event) {
        return _vm.movePage('mysoho');
      }
    }
  }, [_c('span', [_vm._v("마이소호 바로가기")])])])])])]), _c('section', {
    staticClass: "about_start about_start--solution",
    class: _vm.loggedClass
  }, [_c('article', [_vm._m(15), _c('button', {
    staticClass: "lnk__start--dark ga_f_18",
    on: {
      "click": function click($event) {
        return _vm.movePage('/accounts/signup');
      }
    }
  }, [_vm._v(" 무료 시작하기 ")])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "keyvisual__thumb"
  }, [_c('img', {
    staticClass: "thumb01",
    attrs: {
      "src": require("@/assets/images/intro/img_keyvisual01.png"),
      "alt": ""
    }
  }), _c('img', {
    staticClass: "thumb02",
    attrs: {
      "src": require("@/assets/images/intro/img_keyvisual02.png"),
      "alt": ""
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dl', {
    staticClass: "section__title"
  }, [_c('dt', [_vm._v("Qpick 사용안내")]), _c('dd', [_vm._v("빠르게 완성되는 나만의 쇼핑몰")]), _c('dd', [_vm._v("전문가 도움없이"), _c('br'), _vm._v("완성되는 나만의 쇼핑몰")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "thumb"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_visual01.png")
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dl', [_c('dt', [_vm._v("테마 선택하기")]), _c('dd', [_vm._v(" 데스크탑, 모바일 디자인이 통합된 트렌디한 반응형 쇼핑몰 테마를 제공합니다. ")]), _c('dd', [_vm._v("트렌디한 반응형 쇼핑몰 테마 제공")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "thumb"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_visual02.png")
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dl', [_c('dt', [_vm._v("디자인 편집")]), _c('dd', [_vm._v(" 디자이너, 개발자의 전문적인 도움없이 원하는 디자인을 간단하게 편집하세요. ")]), _c('dd', [_vm._v("전문적인 도움없이 디자인 간단 편집")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "thumb"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_visual03.png")
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dl', [_c('dt', [_vm._v("디자인 내보내기")]), _c('dd', [_vm._v(" 편집된 디자인 저장 후 ‘내보내기’만 하면 쇼핑몰 디자인이 완료됩니다. ")]), _c('dd', [_vm._v("완료된 쇼핑몰 디자인 내보내기")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dl', {
    staticClass: "section__title"
  }, [_c('dt', [_vm._v("Qpick 반응형 테마")]), _c('dd', [_vm._v("트렌디한 반응형"), _c('br'), _vm._v("테마 디자인")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "design__button"
  }, [_c('img', {
    staticClass: "swiper-button-prev",
    attrs: {
      "src": require("@/assets/images/intro/btn_prev.svg"),
      "alt": "이전"
    }
  }), _c('img', {
    staticClass: "swiper-button-next",
    attrs: {
      "src": require("@/assets/images/intro/btn_next.svg"),
      "alt": "다음"
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "section04"
  }, [_c('dl', {
    staticClass: "section__title"
  }, [_c('dt', [_vm._v("Qpick 웹 빌더 솔루션")]), _c('dd', [_vm._v("기능까지 완벽한 쇼핑몰 디자인")])]), _c('ul', {
    staticClass: "desc04"
  }, [_c('li', [_c('p', [_vm._v("언제 어디서나"), _c('br'), _c('strong', [_vm._v("반응형 에디터")])])]), _c('li', [_c('p', [_vm._v("쉬운 디자인 편집"), _c('br'), _c('strong', [_vm._v("직관적인 에디터")])])]), _c('li', [_c('p', [_c('strong', [_vm._v("디바이스별")]), _c('br'), _vm._v("디자인 관리")])]), _c('li', [_c('p', [_c('strong', [_vm._v("컴포넌트")]), _c('br'), _vm._v("맞춤 제작")])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dl', {
    staticClass: "section__title"
  }, [_c('dt', [_vm._v("Qpick 테마 커스터마이징")]), _c('dd', [_vm._v("블럭 단위로 커스터마이징하는"), _c('br'), _vm._v("나만의 맞춤 쇼핑몰")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "desc05__list desc05__list--desktop"
  }, [_c('li'), _c('li'), _c('li'), _c('li'), _c('li')]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dl', {
    staticClass: "section__title"
  }, [_c('dt', [_vm._v("쇼핑몰 솔루션 연동")]), _c('dd', [_vm._v("큐픽에서 제작한 테마를"), _c('br'), _vm._v("내 쇼핑몰 테마로")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desc06__visual"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_solution01.png"),
      "alt": ""
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v("고민없는 쇼핑몰 제작"), _c('br'), _vm._v("큐픽으로 시작하세요.")]);
}]

export { render, staticRenderFns }