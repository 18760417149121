<template>
  <div>
    <div class="container">
      <section class="section01" :class="loggedClass">
        <div class="keyvisual">
          <div class="keyvisual__text">
            <p>쇼핑몰 디자인과 기능, 빠르게 pick. Qpick!</p>
            <button
              class="lnk__start ga_f_14"
              @click="movePage('/accounts/signup')"
            >
              무료 시작하기
            </button>
          </div>
          <div class="keyvisual__thumb">
            <img
              class="thumb01"
              src="~@/assets/images/intro/img_keyvisual01.png"
              alt=""
            />
            <img
              class="thumb02"
              src="~@/assets/images/intro/img_keyvisual02.png"
              alt=""
            />
          </div>
        </div>
      </section>
      <section class="section02">
        <dl class="section__title">
          <dt>Qpick 사용안내</dt>
          <dd>빠르게 완성되는 나만의 쇼핑몰</dd>
          <dd>전문가 도움없이<br />완성되는 나만의 쇼핑몰</dd>
        </dl>
        <div class="feature">
          <swiper
            ref="guideSwiper"
            :options="guideSwiperOption"
            @slideChange="onSlideChange"
            class="feature__thumb"
          >
            <swiper-slide>
              <img src="~@/assets/images/intro/img_visual1.png" />
            </swiper-slide>
            <swiper-slide>
              <img src="~@/assets/images/intro/img_visual2.png" />
            </swiper-slide>
            <swiper-slide>
              <img src="~@/assets/images/intro/img_visual3.png" />
            </swiper-slide>
          </swiper>
          <ul class="feature__tab">
            <li :class="{active: isTab == 1}" @click="onslideTo(1)">
              <div class="thumb">
                <img src="~@/assets/images/intro/img_visual01.png" />
              </div>
              <dl>
                <dt>테마 선택하기</dt>
                <dd>
                  데스크탑, 모바일 디자인이 통합된 트렌디한 반응형 쇼핑몰 테마를
                  제공합니다.
                </dd>
                <dd>트렌디한 반응형 쇼핑몰 테마 제공</dd>
              </dl>
            </li>
            <li :class="{active: isTab == 2}" @click="onslideTo(2)">
              <div class="thumb">
                <img src="~@/assets/images/intro/img_visual02.png" />
              </div>
              <dl>
                <dt>디자인 편집</dt>
                <dd>
                  디자이너, 개발자의 전문적인 도움없이 원하는 디자인을 간단하게
                  편집하세요.
                </dd>
                <dd>전문적인 도움없이 디자인 간단 편집</dd>
              </dl>
            </li>
            <li :class="{active: isTab == 3}" @click="onslideTo(3)">
              <div class="thumb">
                <img src="~@/assets/images/intro/img_visual03.png" />
              </div>
              <dl>
                <dt>디자인 내보내기</dt>
                <dd>
                  편집된 디자인 저장 후 ‘내보내기’만 하면 쇼핑몰 디자인이
                  완료됩니다.
                </dd>
                <dd>완료된 쇼핑몰 디자인 내보내기</dd>
              </dl>
            </li>
          </ul>
        </div>
      </section>
      <section class="section03">
        <div class="design">
          <div class="design__control">
            <dl class="section__title">
              <dt>Qpick 반응형 테마</dt>
              <dd>트렌디한 반응형<br />테마 디자인</dd>
            </dl>
            <button
              class="lnk__more ga_f_15"
              @click="movePage('/introduction/theme-list?id=3')"
            >
              <span>전체보기</span>
            </button>
          </div>
          <div class="design__swiper">
            <swiper :options="themeSwiperOption" ref="themeSwiper">
              <swiper-slide>
                <img
                  src="https://storage.qpicki.com/qpick/000/000/000/d1c435a40a651a1c51b885d877270e4a.png"
                  alt=""
                />
              </swiper-slide>
              <swiper-slide>
                <img
                  src="https://storage.qpicki.com/qpick/000/000/000/15df6399261c697724eb9ba55036b253.png"
                  alt=""
                />
              </swiper-slide>
              <swiper-slide>
                <img
                  src="https://storage.qpicki.com/qpick/000/000/002/1bfa0636dea5e7ecb0092c87ed527f15.png"
                  alt=""
                />
              </swiper-slide>
              <swiper-slide>
                <img
                  src="https://storage.qpicki.com/qpick/000/000/002/83ff798d897dcf28e691311c857b31fd.png"
                  alt=""
                />
              </swiper-slide>
              <swiper-slide>
                <img
                  src="https://storage.qpicki.com/qpick/000/000/002/8e1eea0240cac068883378933292f808.png"
                  alt=""
                />
              </swiper-slide>
              <swiper-slide>
                <img
                  src="https://storage.qpicki.com/qpick/000/000/002/3a31bc6b15474f58f3fcc026e6b139f8.png"
                  alt=""
                />
              </swiper-slide>
              <swiper-slide>
                <img
                  src="https://storage.qpicki.com/qpick/000/000/003/151c3a3aca25d3f67ef31deafe99d08e.png"
                  alt=""
                />
              </swiper-slide>
              <swiper-slide>
                <img
                  src="https://storage.qpicki.com/qpick/000/000/003/349dab3640a14bcad18b31baacb5850c.png"
                  alt=""
                />
              </swiper-slide>
            </swiper>
            <div class="design__button">
              <img
                class="swiper-button-prev"
                src="~@/assets/images/intro/btn_prev.svg"
                alt="이전"
              />
              <img
                class="swiper-button-next"
                src="~@/assets/images/intro/btn_next.svg"
                alt="다음"
              />
            </div>
          </div>
        </div>
      </section>
      <section class="section04">
        <dl class="section__title">
          <dt>Qpick 웹 빌더 솔루션</dt>
          <dd>기능까지 완벽한 쇼핑몰 디자인</dd>
        </dl>
        <ul class="desc04">
          <li>
            <p>언제 어디서나<br /><strong>반응형 에디터</strong></p>
          </li>
          <li>
            <p>쉬운 디자인 편집<br /><strong>직관적인 에디터</strong></p>
          </li>
          <li>
            <p><strong>디바이스별</strong><br />디자인 관리</p>
          </li>
          <li>
            <p><strong>컴포넌트</strong><br />맞춤 제작</p>
          </li>
        </ul>
      </section>
      <section class="section05">
        <div class="desc05">
          <dl class="section__title">
            <dt>Qpick 테마 커스터마이징</dt>
            <dd>블럭 단위로 커스터마이징하는<br />나만의 맞춤 쇼핑몰</dd>
          </dl>
          <button
            class="lnk__more--solid ga_f_16"
            @click="movePage('/introduction/component-list')"
          >
            <span>전체보기</span>
          </button>
          <ul class="desc05__list desc05__list--desktop">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <div class="desc05__list desc05__list--mobile">
            <swiper ref="componentSwiper" :options="componentSwiperOption">
              <swiper-slide>
                <img src="~@/assets/images/intro/img_mframe01.png" alt="" />
              </swiper-slide>
              <swiper-slide>
                <img src="~@/assets/images/intro/img_mframe02.png" alt="" />
              </swiper-slide>
              <swiper-slide>
                <img src="~@/assets/images/intro/img_mframe03.png" alt="" />
              </swiper-slide>
              <swiper-slide>
                <img src="~@/assets/images/intro/img_mframe04.png" alt="" />
              </swiper-slide>
              <swiper-slide>
                <img src="~@/assets/images/intro/img_mframe05.png" alt="" />
              </swiper-slide>
              <swiper-slide>
                <img src="~@/assets/images/intro/img_mframe06.png" alt="" />
              </swiper-slide>
              <swiper-slide>
                <img src="~@/assets/images/intro/img_mframe07.png" alt="" />
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </section>
      <section class="section06">
        <div class="desc06">
          <dl class="section__title">
            <dt>쇼핑몰 솔루션 연동</dt>
            <dd>큐픽에서 제작한 테마를<br />내 쇼핑몰 테마로</dd>
          </dl>
          <div class="desc06__visual">
            <img src="~@/assets/images/intro/img_solution01.png" alt="" />
          </div>
          <div class="desc06__text">
            <div class="desc06__text--solution">
              <img src="~@/assets/images/intro/bi_mysoho.png" alt="mysoho" />
              <p>
                마이소호는 초기 창업자의 사용에 최적화되어 누구나 쉽게 사용할 수
                있고 다양하게 상품을 판매할 수 있는 강력한 기능을 제공합니다.
                <button @click="movePage('interlock-mysoho')">
                  연동방법 보러가기
                </button>
              </p>
              <button class="lnk__solution ga_f_17" @click="movePage('mysoho')">
                <span>마이소호 바로가기</span>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section class="about_start about_start--solution" :class="loggedClass">
        <article>
          <p>고민없는 쇼핑몰 제작<br />큐픽으로 시작하세요.</p>
          <button
            class="lnk__start--dark ga_f_18"
            @click="movePage('/accounts/signup')"
          >
            무료 시작하기
          </button>
        </article>
      </section>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import "@/assets/scss/intro.scss";

const ModuleIntroCommon = createNamespacedHelpers("ModuleIntroCommon");
const ModuleAuth = createNamespacedHelpers("ModuleAuth");

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      isTab: 1,
      guideSwiperOption: {
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
        effect: "fade"
      },
      themeSwiperOption: {
        observer: true,
        observeParents: true,
        slidesPerView: "auto",
        loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        centeredSlides: true,
        spaceBetween: 16,
        breakpoints: {
          768: {
            centeredSlides: false,
            spaceBetween: 40
          },
          1024: {
            centeredSlides: false,
            spaceBetween: 56
          }
        }
      },
      componentSwiperOption: {
        observer: true,
        observeParents: true,
        slidesPerView: "auto",
        loop: true,
        autoplay: {
          delay: 0,
          disableOnInteraction: false
        },
        speed: 7500,
        spaceBetween: 16
      }
    };
  },
  computed: {
    loggedClass() {
      return {
        notLogged: this.getLoginStatus
      };
    },
    ...ModuleAuth.mapGetters(["getLoginStatus"])
  },
  created() {
    this.setCurrentMenuInfo({
      id: 0,
      name: "메인",
      path: "/introduction/main"
    });
  },
  methods: {
    onSlideChange() {
      this.isTab = this.$refs.guideSwiper.$swiper.realIndex + 1;
    },
    onslideTo(idx) {
      this.$refs.guideSwiper.$swiper.slideTo(--idx);
    },
    onScroll() {
      let keyvisual = document.querySelector(".section01");
      if (keyvisual.offsetTop < window.scrollY + keyvisual.scrollHeight) {
        keyvisual.classList.add("active");
      } else {
        keyvisual.classList.remove("active");
      }
      let visual = document.querySelector(".section05");
      if (visual.offsetTop < window.scrollY + visual.scrollHeight) {
        visual.classList.add("active");
      } else {
        visual.classList.remove("active");
      }
    },
    // 페이지 이동
    movePage(path) {
      this.$emit("movePage", path);
    },
    ...ModuleIntroCommon.mapMutations(["setCurrentMenuInfo"])
  },
  beforeUpdate() {
    this.onScroll();
  },
  mounted() {
    this.onScroll();
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  }
};
</script>

<style lang="scss" scoped>
div,
button {
  font-family: "Pretendard", "Malgun Gothic", sans-serif;
  color: #1c1e23;
  line-height: 1.25;
}
.section01.notLogged {
  .lnk__start {
    visibility: hidden;
  }
  @media (max-width: 767px) {
    .keyvisual {
      height: 460px;
    }
  }
}
.about_start.notLogged {
  display: none;
}
.container {
  z-index: 1;
}
.section__title dt {
  color: #2cb292;
  font-size: 18px;
  font-weight: bold;
}
.section__title dd {
  margin-top: 8px;
  color: #1c1e23;
  font-size: 48px;
  font-weight: bold;
  line-height: 64px;
}
.section__title {
  text-align: center;
}
@media (min-width: 1024px) {
  .container section {
    padding: 160px 0 140px;
    box-sizing: border-box;
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .container section {
    padding: 100px 0 80px;
    box-sizing: border-box;
  }
}
@media (max-width: 767px) {
  .container section {
    padding: 80px 0;
    box-sizing: border-box;
  }
}
/* section01 */
.container section.section01 {
  overflow: hidden;
  padding: 0;
  background-color: #1c1e23;
}
.keyvisual {
  position: relative;
  max-width: 1392px;
  height: 700px;
  margin: 0 auto;
  padding: 0 0 0 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.keyvisual__text {
  width: 523px;
  flex-shrink: 0;
  transition: 1.5s;
  transform: translateY(25%);
}
.active .keyvisual__text {
  transform: translateY(0);
}
.keyvisual__text p {
  height: 144px;
  background-image: url(~@/assets/images/intro/txt_keyvisual.svg);
  background-repeat: no-repeat;
  background-position: center;
  text-indent: -9999px;
}
.keyvisual__text .lnk__start {
  margin-top: 100px;
}
.keyvisual__thumb {
  position: relative;
  max-width: 971px;
  width: 100%;
  font-size: 0;
  margin-right: -8%;
}
.keyvisual__thumb .thumb01 {
  position: relative;
  width: 100%;
  transition: 1.5s;
  transform: translateY(25%);
}
.active .keyvisual__thumb .thumb01 {
  transform: translateY(0);
}
.keyvisual__thumb .thumb02 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: 1.5s 0.5s;
  transform: translateY(25%);
}
.active .keyvisual__thumb .thumb02 {
  transform: translateY(0);
}
@media (max-width: 1023px) and (min-width: 768px) {
  .keyvisual {
    max-width: 768px;
    height: 390px;
  }
  .keyvisual__text {
    width: 324px;
  }
  .keyvisual__text p {
    height: 76px;
    background-image: url(~@/assets/images/intro/txt_keyvisual_tablet.svg);
    background-size: auto 76px;
  }
  .keyvisual__text .lnk__start {
    margin-top: 56px;
  }
  .keyvisual__thumb {
    zoom: 0.46;
  }
}
@media (max-width: 767px) {
  .keyvisual {
    height: 508px;
    padding: 0;
    flex-direction: column;
  }
  .keyvisual__text {
    width: 100%;
    margin-top: 56px;
    padding: 0 16px;
    box-sizing: border-box;
  }
  .keyvisual__text p {
    height: 76px;
    background-image: url(~@/assets/images/intro/txt_keyvisual_mobile.svg);
    background-size: contain;
  }
  .keyvisual__text .lnk__start {
    margin: 40px auto 0;
    margin-top: 272px;
  }
  .keyvisual__thumb {
    zoom: 0.35;
    top: -820px;
    margin-right: 0;
  }
}
/* section02 */
@media (min-width: 768px) {
  .feature {
    max-width: 1400px;
    margin: 80px auto 0;
    padding: 0 24px;
    display: grid;
    column-gap: 40px;
    grid-template-columns: minmax(348px, 770px) minmax(332px, 542px);
  }
  .feature__thumb {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }
  .feature__thumb .swiper-slide {
    display: flex;
  }
  .feature__thumb .swiper-slide img {
    padding: 48px 48px 0;
    box-sizing: border-box;
    width: 100%;
    filter: drop-shadow(0px -4px 8px rgba(0, 0, 0, 0.1));
  }
  .feature__tab {
    .thumb {
      display: none;
    }
    li {
      padding: 32px 40px;
      border-radius: 16px;
      cursor: pointer;

      &:nth-child(1) dt {
        background-image: url(~@/assets/images/intro/bul_feature01.svg);
        background-repeat: no-repeat;
        background-position: center left;
      }
      &.active:nth-child(1) dt {
        background-image: url(~@/assets/images/intro/bul_feature01_active.svg);
      }
      &:nth-child(2) dt {
        background-image: url(~@/assets/images/intro/bul_feature02.svg);
        background-repeat: no-repeat;
        background-position: center left;
      }
      &.active:nth-child(2) dt {
        background-image: url(~@/assets/images/intro/bul_feature02_active.svg);
      }
      &:nth-child(3) dt {
        background-image: url(~@/assets/images/intro/bul_feature03.svg);
        background-repeat: no-repeat;
        background-position: center left;
      }
      &.active:nth-child(3) dt {
        background-image: url(~@/assets/images/intro/bul_feature03_active.svg);
      }
      &.active {
        display: block;
        background-color: #2cb292;
      }
      &.active dt,
      &.active dd {
        color: #fff;
      }
    }
    dt {
      height: 28px;
      padding-left: 38px;
      color: #1c1e23;
      font-size: 24px;
      font-weight: bold;
    }
    dd {
      margin-top: 10px;
      padding-left: 6px;
      color: #676b74;
      font-size: 20px;
    }
  }
  .section02 .section__title dd:nth-child(2) {
    display: block;
  }
  .section02 .section__title dd:nth-child(3) {
    display: none;
  }
}
@media (max-width: 767px) {
  .feature {
    column-gap: 40px;
    padding: 0 16px;
    box-sizing: border-box;
  }
  .feature__thumb {
    display: none;
  }
  .feature__tab {
    margin-top: 40px;
    .thumb {
      display: flex;
      margin-top: 24px;
      img {
        width: 100%;
      }
    }
    dt {
      margin-top: 16px;
      padding: 0 4px;
      font-weight: bold;
    }
    dd {
      margin-top: 8px;
      padding: 0 4px;
    }
  }
  .section02 .section__title dd:nth-child(2) {
    display: none;
  }
  .section02 .section__title dd:nth-child(3) {
    display: block;
  }
}
@media (min-width: 1024px) {
  .feature__tab dd:nth-child(2) {
    display: block;
  }
  .feature__tab dd:nth-child(3) {
    display: none;
  }
}
@media (max-width: 1023px) {
  .section__title dt {
    font-size: 15px;
  }
  .section__title dd {
    font-size: 28px;
    line-height: 36px;
  }
  .feature__tab {
    dt {
      font-size: 20px;
    }
    dd {
      font-size: 16px;

      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        display: block;
      }
    }
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .feature {
    max-width: 720px;
  }
  .feature__thumb {
    width: 348px;
  }
  .feature__thumb .swiper-slide img {
    padding: 24px 24px 0;
  }
  .feature__tab {
    width: 332px;

    li {
      padding: 18px 24px;
      cursor: pointer;
    }
  }
}
/* section03 */
.section03 {
  overflow: hidden;
}
.design {
  position: relative;
  height: 542px;
  width: 50%;
  margin: 0 auto;
  padding-right: 196px;
}
.design .section__title {
  text-align: left;
}
.design__control {
  width: 414px;
  padding-top: 24px;
}
.design__control .lnk__more {
  position: absolute;
  bottom: 40px;
  left: 0;
}
.design__swiper {
  position: absolute;
  top: 0;
  left: 414px;
  font-size: 0;
  width: 100%;
}
.design__swiper .swiper-wrapper {
  display: flex;
}
.design__swiper .swiper-slide {
  width: 400px;
}
.design__swiper .swiper-slide img {
  width: 400px;
  border-radius: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.design__button {
  z-index: 1;
  position: absolute;
  top: 225px;
  left: -406px;
  height: 48px;
  display: flex;
  column-gap: 24px;
}
.design__button img {
  position: relative;
  left: auto;
  right: auto;
  width: 48px;
  height: 48px;
}
@media (max-width: 1023px) and (min-width: 768px) {
  .design__button {
    top: 150px;
    left: -318px;
    height: 40px;
    column-gap: 24px;
  }
  .design__button img {
    width: 40px;
    height: 40px;
  }
  .design {
    height: 304px;
    width: 720px;
    box-sizing: border-box;
  }
  .design__control {
    width: 326px;
  }
  .design__swiper {
    left: 326px;
  }
  .design__swiper .swiper-slide {
    width: 224px;
  }
  .design__swiper .swiper-slide img {
    width: 224px;
  }
  .design__control .lnk__more {
    bottom: 10px;
  }
}
@media (max-width: 767px) {
  .section03 {
    background-color: #f8fafc;
  }
  .design {
    width: auto;
    height: 525px;
    padding-right: 0;
  }
  .design .section__title {
    text-align: center;
  }
  .design__control {
    width: 100%;
    padding-top: 0;
    text-align: center;
  }
  .swiper-container {
    overflow: visible;
  }
  .design__swiper {
    top: 140px;
    left: auto;
  }
  .design__swiper .swiper-slide {
    width: 208px;
  }
  .design__swiper .swiper-slide img {
    width: 208px;
    border-radius: 8px;
  }
  .design__control .lnk__more {
    left: calc(50% - 120px);
    bottom: 0;
  }
  .design__button {
    display: none;
  }
}
/* section04 */
.desc04 {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  gap: 24px;
}
.desc04 li {
  position: relative;
  background-color: #eff2f8;
  border-radius: 16px;
}
.desc04 li p {
  color: #31363d;
  text-align: center;
}
.desc04 li:nth-child(1) {
  background-image: url(~@/assets/images/intro/img_builder01.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.desc04 li:nth-child(2) {
  background-image: url(~@/assets/images/intro/img_builder02.png);
  background-position: center bottom;
  background-repeat: no-repeat;
}
.desc04 li:nth-child(3) {
  background-image: url(~@/assets/images/intro/img_builder03.png);
  background-position: center bottom;
  background-repeat: no-repeat;
}
.desc04 li:nth-child(4) {
  background-image: url(~@/assets/images/intro/img_builder04.png);
  background-position: center bottom;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .desc04 {
    max-width: 1144px;
    margin: 80px auto 0;
    padding: 0 56px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .desc04 li p {
    margin-top: 56px;
    font-size: 32px;
    line-height: 40px;
  }
  .desc04 li:nth-child(1) {
    grid-row: span 2;
    height: 728px;
  }
  .desc04 li:nth-child(1) p {
    margin-top: 96px;
  }
  .desc04 li:nth-child(1) {
    grid-row: span 2;
  }
  .desc04 li:nth-child(2) {
    grid-column: span 2;
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .desc04 {
    max-width: 768px;
    margin-top: 56px;
    padding: 0 24px;
  }
  .desc04 li p {
    margin-top: 24px;
    font-size: 22px;
    line-height: 28px;
  }
  .desc04 li:nth-child(1) p {
    margin-top: 64px;
  }
  .desc04 li:nth-child(1) {
    height: 536px;
    background-size: 270px auto;
  }
  .desc04 li:nth-child(2) {
    background-size: 395px auto;
  }
  .desc04 li:nth-child(3),
  .desc04 li:nth-child(4) {
    background-size: 225px auto;
  }
}
@media (max-width: 767px) {
  .section04 .section__title dd {
    width: 200px;
    margin: 0 auto;
  }
  .desc04 {
    margin-top: 40px;
    padding: 0 16px;
  }
  .desc04 li {
    height: 296px;
  }
  .desc04 li p {
    margin-top: 40px;
    font-size: 22px;
    line-height: 28px;
  }
  .desc04 li:nth-child(1) {
    background-image: url(~@/assets/images/intro/img_mbuilder01.png);
  }
  .desc04 li:nth-child(2) {
    background-image: url(~@/assets/images/intro/img_mbuilder02.png);
  }
  .desc04 li:nth-child(3) {
    background-image: url(~@/assets/images/intro/img_mbuilder03.png);
  }
  .desc04 li:nth-child(4) {
    background-image: url(~@/assets/images/intro/img_mbuilder04.png);
  }
}
/* section05 */
.section05 {
  overflow: hidden;
  background-color: #1c1e23;
}
.container section.section05 {
  height: 636px;
  padding: 0;
}
.section05 .section__title {
  padding-top: 160px;
}
.section05 .section__title dd {
  color: #fff;
}
.section05 .lnk__more--solid {
  margin: 100px auto 0;
}
.desc05 {
  position: relative;
  height: 100%;
  margin: 0 auto;
}
@keyframes fade-up-left {
  from {
    transform: translate(40%, 20%);
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes fade-down-left {
  from {
    transform: translate(40%, -20%);
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes fade-up-right {
  from {
    transform: translate(-60%, 20%);
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes fade-down-right {
  from {
    transform: translate(-60%, -20%);
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .container section.section05 {
    height: 466px;
  }
  .section05 .section__title {
    padding-top: 100px;
    padding-top: 160px;
  }
  .section05 .lnk__more--solid {
    margin-top: 56px;
  }
  .desc05__list {
    zoom: 0.55;
  }
}
@media (min-width: 768px) {
  .desc05__list--desktop {
    display: block !important;
  }
  .desc05__list--mobile {
    display: none !important;
  }
  .desc05__list {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
  }
  .desc05__list li {
    position: absolute;
    background-repeat: no-repeat;
  }
  .desc05__list li:nth-child(1) {
    width: 600px;
    height: 379px;
    background-image: url(~@/assets/images/intro/img_frame01.png);
    left: -980px;
    top: -80px;
  }
  .desc05__list li:nth-child(2) {
    width: 524px;
    height: 525px;
    background-image: url(~@/assets/images/intro/img_frame02.png);
    left: -830px;
    bottom: -280px;
  }
  .desc05__list li:nth-child(3) {
    width: 227px;
    height: 398px;
    background-image: url(~@/assets/images/intro/img_frame03.png);
    top: -316px;
    right: -430px;
  }
  .desc05__list li:nth-child(4) {
    width: 485px;
    height: 246px;
    background-image: url(~@/assets/images/intro/img_frame04.png);
    top: 55px;
    right: -1088px;
  }
  .desc05__list li:nth-child(5) {
    width: 591px;
    height: 267px;
    background-image: url(~@/assets/images/intro/img_frame05.png);
    bottom: -24px;
    right: -872px;
  }
  .active {
    .desc05__list {
      li:nth-child(1) {
        animation: fade-up-left 2s linear forwards;
      }
      li:nth-child(2) {
        animation: fade-down-left 2s linear forwards;
      }
      li:nth-child(3) {
        animation: fade-up-right 2s linear forwards;
      }
      li:nth-child(4) {
        animation: fade-up-right 2s linear forwards;
      }
      li:nth-child(5) {
        animation: fade-down-right 2s linear forwards;
      }
    }
  }
}
@media (max-width: 767px) {
  .container section.section05 {
    height: 428px;
  }
  .section05 .section__title {
    padding-top: 80px;
  }
  .section05 .section__title dd {
    margin-left: auto;
    margin-right: auto;
    width: 240px;
    word-break: keep-all;
  }
  .section05 .lnk__more--solid {
    margin-top: 40px;
  }
  .desc05__list--desktop {
    display: none !important;
  }
  .desc05__list--mobile {
    display: block !important;
  }
  .desc05__list--mobile .swiper-wrapper {
    transition-timing-function: linear;
  }
  .desc05__list {
    margin-top: 32px;
  }
  .desc05__list .swiper-slide {
    width: 176px !important;
    height: 95px;
  }
  .desc05__list .swiper-slide img {
    width: 100%;
    height: 100%;
  }
}
/* section06 */
.desc06 {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 24px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  align-items: center;
}
.desc06__visual {
  display: flex;
}
.desc06__visual img {
  width: 100%;
  height: 496px;
  object-fit: cover;
  border-radius: 16px;
}
.desc06__text--solution {
  margin-top: 40px;
}
.desc06__text--solution p {
  margin-top: 16px;
  color: #676b74;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.6px;
  word-break: keep-all;
}
.desc06__text--solution p button {
  display: inline-block;
  padding-left: 8px;
  padding-top: 1px;
  vertical-align: top;
  color: #2e65f3;
  text-decoration: underline;
}
.desc06__text--solution .lnk__solution {
  margin-top: 80px;
}
@media (min-width: 1401px) {
  .desc06 {
    column-gap: 80px;
  }
}
@media (min-width: 1024px) {
  .desc06 .section__title {
    text-align: left;
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .desc06 {
    max-width: 768px;
    grid-template-columns: 348px 332px;
  }
  .desc06 .section__title {
    text-align: left;
  }
  .desc06__visual img {
    height: 258px;
  }
  .desc06__text--solution p {
    margin-top: 8px;
    font-size: 16px;
  }
  .desc06__text--solution .lnk__solution {
    margin-top: 32px;
  }
}
@media (min-width: 768px) {
  .desc06__visual {
    grid-row: 1 / span 2;
  }
}
@media (max-width: 767px) {
  .desc06 {
    padding: 0 16px;
    grid-template-columns: auto;
  }
  .desc06__visual {
    margin-top: 40px;
  }
  .desc06__visual img {
    height: 258px;
  }
  .desc06__text--solution {
    margin-top: 16px;
  }
  .desc06__text--solution p {
    margin-top: 8px;
    font-size: 16px;
  }
  .desc06__text--solution p button {
    display: block;
    padding-left: 0;
  }
  .desc06__text--solution .lnk__solution {
    margin: 40px auto 0;
  }
}
</style>
